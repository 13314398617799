import { Moment } from "moment/moment"
import { FilesList } from "Components/File/types"

export type YandexModalFormValues = {
  [YandexModalFields.type]: string
  [YandexModalFields.period]?: [Moment, Moment] | Moment
  [YandexModalFields.files]: FilesList[]
}

export type YandexModalSaveFormValues = {
  [YandexModalFields.type]: string
  [YandexModalFields.period]: Moment
  [YandexModalFields.files]: FilesList[]
}

export type YandexModalProps = {
  close: () => void
}

export enum YandexModalFields {
  type = `type`,
  period = `period`,
  files = `files`
}
