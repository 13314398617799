export const rulesText = {
  required: `Обязательно для заполнения`,
  login: `Неверный формат  поля ввода`,
  email: `Неверный формат электронной почты`,
  phone: `Неверный формат телефона`,
  required_long: `Поле обязательно для заполнения`,
  required_short: `Поле обязательно для заполнения`,
  number: `Неверный формат суммы`,
  default: `Неверный формат`,
  comma_number: `Неверный формат: ХХХ,ХХ`,
  dot_number: `Неверный формат: ХХХ.ХХ`,
  name: `Неверный формат ФИО`,
  max_length_100: `Максимальная длина 100 символов`,
  inn: `Количество символов должно быть 10 или 12`
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const checkEmail = (email?: string) =>
  !!email &&
  !!String(email)
    .toLowerCase()
    .match(emailRegex)
