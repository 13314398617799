import * as React from "react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
  useLocation
} from "react-router-dom"

import useErrorBoundary from "use-error-boundary"

import { useRequest } from "api/hooks"
import canClickOnLogo, { baseRoute } from "api/role"

import getMenu, { getDictionary } from "Containers/HallPicker/helper"
import { IUSERState } from "store/auth/types"
import { IDevelopState } from "store/developer/types"
import { IDictionaryState } from "store/dictionary/types"

import {
  MAIN_PAGE,
  CASHBOX_PAGE,
  ERROR_PAGE,
  GAMES_PAGE,
  CONSOLIDATED_PAGE,
  NOTIFICATION_PAGE,
  CHANGE_PHONE_PAGE,
  GUEST_INFO,
  MESSAGES_PAGE,
  REPORT_CONSOLIDATED_CUPIS,
  REPORT_NONCASH_BALANCE_OPERATION,
  REPORT_NONCASH_TRANSACTION,
  REPORT_NONCASH_CLUB_BALANCE,
  SUPPORT_PROPOSAL,
  HANDBOOK_NEW_HALL,
  MAILING_PAGE,
  SUPPORT_PENALTY_ADMIN,
  SUPPORT_PENALTY_USER,
  CONTACTS_PAGE,
  VISIT,
  GUIDEBOOK,
  GUESTS_HISTORY,
  SMS,
  SPORT_BOOM,
  GUESTS_BALANCE,
  WIKI,
  baseLayoutText,
  ROOT,
  routeBackground,
  OFFICIAL_SMS,
  NOMINALS,
  INSURANCES,
  SUPERWIN,
  TOP_THREE,
  RACE,
  PAYROLLS,
  PAYMENT,
  HISTORY_BETS,
  ORDER_PAYOUTS,
  REPORT_HALL,
  HALL_CARD,
  REGISTRATION_CHECK,
  PROMOTION_BET_CHECK,
  PROMOTION_GOLD_RUSH,
  PROMOTION_BIRTHDAY,
  SOPD_REPORT,
  HALL_SPORT_BROADCAST,
  HALL_ACCESS,
  HALL_GEOSERVICES,
  GUEST_FILTERS,
  YANDEX_TAXI
} from "Containers/BaseLayout/constants"
import LeftMenu from "./LeftMenu"

import { ReactComponent as LogOutIcon } from "Components/icons/log-out.svg"
import { ReactComponent as BetLogoIcon } from "Components/icons/bb-logo.svg"
import { ReactComponent as MenuCloseIcon } from "Components/icons/close.svg"
import { ReactComponent as MenuToggleIcon } from "./LeftMenu/menu_toggle.svg"
import { ReactComponent as MenuToggleIconWiki } from "Components/icons/lk_wiki_logo.svg"

import HallPicker from "Components/HallPicker"

import MessagesComponent from "./Messages"
import { MessageProps } from "./Messages/types"

import { makeLogout } from "Containers/Authentication/helper"
import HallPickerPage from "Containers/HallPicker"
import MainPage from "Containers/Main"
import CashboxComponent from "Containers/Cashbox"
import ConsolidatedPage from "Containers/Consolidated"
import GamePage from "Containers/Games"
import NotificationsComponent from "Containers/Tools/Notifications"
import MessagesRoute from "Containers/Messages"
import BalanceOperation from "Containers/Noncash/BalanceOperation"
import Transaction from "Containers/Noncash/Transaction"
import Authentication from "Containers/Authentication"
import GuestInfoComponent from "Containers/Promotion/GuestInfo"
import Mailing from "Containers/Mailing"
import GuestsHistory from "Containers/GuestsHistory"
import Cupis from "Containers/Reports/Cupis"
import ReportHall from "Containers/Reports/Hall"
import SportBoomReport from "Containers/SportBoom"
import ErrorPage from "Containers/Errors"
import SuperwinReport from "Containers/Superwin"
import TopThreeComponent from "Containers/Promotions/TopThree"

import ChangePhoneComponent from "Containers/Order/ChangePhone"
import PayrollsComponent from "Containers/Order/Payrolls"
import Payment from "Containers/Order/Payment"

import { storeDictionaryData } from "store/dictionary/actions"
import { getDeveloperList } from "store/developer/selector"
import { getCurrentHall } from "store/halls/selector"
import { addLink } from "store/developer/actions"
import { logoutUser, loginUser } from "store/auth/actions"
import { getIsAuth } from "store/auth/selector"
import { getMenuOptions, getMenuRoutes } from "store/menu/selector"
import { getMenuMain } from "store/menu/selector"
import { IMenuState } from "store/menu/types"
import { storeMenuData } from "store/menu/actions"
import ModalComponent from "Components/Modal"
import {
  EmergencyDataType,
  MessagesResponse,
  MessageStoreState
} from "store/message/types"
import { storeEmergency } from "store/message/actions"
import { getEmergencyCount } from "store/message/selector"
import ClubBalance from "../Noncash/ClubBalance"
import { BaseLayoutUserProps } from "./types"
import Proposal from "Containers/Support/Proposal"
import AdminPenalty from "../Support/Penalty/Admin"
import UserPenalty from "../Support/Penalty/User"
import NewHall from "../Handbooks/NewHall"
import ContactComponent from "../Contacts"
import VisitComponent from "../Visit"
import Guidebook from "../Guidebook"
import SmsComponent from "../Sms"
import GuestsBalance from "../GuestsBalance"
import { Wiki } from "../Wiki"
import Button from "Components/Button"
import DeveloperComponent from "Components/DeveloperComponent"
import OfficialSms from "../OfficialSms"
import { Nominals } from "../Nominals"
import InsurancesComponent from "../Insurances"
import { checkRole, roleConstants } from "constants/index"
import Race from "../Race"
import HistoryBets from "../HistoryBets"
import RegistrationCheck from "../RegistrationCheck"
import QiwiOrder from "../Order/Qiwi"
import HallCard from "../HallCard"
import PromotionBetBonus from "../Promotion/Bonuses/BetBonus"
import BirthdayBonus from "../Promotion/Bonuses/BirthdayBonus"
import PromotionGoldRush from "../Promotion/Bonuses/Goldrush"
import SopdReport from "../SopdReport"
import SportsBroadcast from "../Hall/SportsBroadcast"
import Geoservices from "../Hall/Geoservices"
import GuestFilters from "../Guest/Filters"
import AccessReport from "../Hall/Access"
import YandexTaxi from "../Hall/YandexTaxi"

const ErrorBoundaryComponent = () => {
  useEffect(() => {
    localStorage.setItem(`error`, `666`)
  }, [])
  return <ErrorPage />
}

const BaseLayoutUser = ({ logout, bgColor, children }: BaseLayoutUserProps) => {
  const history = useHistory()
  const { ErrorBoundary } = useErrorBoundary()
  const mainMenu = useSelector(getMenuMain)
  const optionsMenu = useSelector(getMenuOptions)
  const { pathname } = useLocation()
  const isWiki = pathname.includes(WIKI)

  const [menuOpen, toggleMenu] = useState<boolean>(false)

  const logoClickRole = canClickOnLogo()

  const isDeveloper = checkRole(`COMPANY-DEVELOPER`)

  const menuReady =
    (!!mainMenu && !!mainMenu.length) || (!!optionsMenu && !!optionsMenu.length)

  return (
    <div
      className={`BaseLayoutWrapper ${isWiki ? "BaseLayoutWrapperWiki" : ""}'`}
    >
      <div
        className={`MenuToggleMobile ${
          menuOpen ? `MenuToggleMobileOpen` : `MenuToggleMobileClose`
        }`}
        onClick={() => toggleMenu(!menuOpen)}
      >
        {!menuOpen ? <MenuToggleIcon /> : <div />}
      </div>
      <div
        className={`BaseLayoutLeftMenu ${
          !menuOpen ? `BaseLayoutLeftMenuDisappear` : ``
        } ${isWiki ? "BaseLayoutLeftMenuWiki" : ""}`}
      >
        {!isWiki ? (
          <div className="FlexRow">
            <div
              className={`BaseLayoutLogoWrapper ${
                logoClickRole ? `BaseLayoutClickableLogo` : ``
              }`}
              onClick={() => {
                if (logoClickRole) history.push(MAIN_PAGE)
              }}
            >
              <BetLogoIcon />
            </div>
            {/* TODO developer popup */}
            {isDeveloper ? (
              <DeveloperComponent iconNumber={Math.floor(Math.random() * 5)} />
            ) : (
              <div />
            )}
            <HallPicker />
            <div
              className="MobileMenuCloseWrapper"
              onClick={() => toggleMenu(!menuOpen)}
            >
              <MenuCloseIcon />
            </div>
          </div>
        ) : (
          <div className="BaseLayoutLeftMenuWikiWrapperRoot">
            <Button
              className="BaseLayoutLeftMenuWikiWrapperBackButton"
              onClick={() => {
                history.push(ROOT)
              }}
            >
              {baseLayoutText.backToLk}
            </Button>
            <div
              className="BaseLayoutLeftMenuWikiWrapper"
              onClick={() => history.push(`${WIKI}/`)}
            >
              <MenuToggleIconWiki />
              <div className="BaseLayoutLeftMenuWikiWrapperText">
                {baseLayoutText.lkWiki}
              </div>
            </div>
          </div>
        )}
        <LeftMenu closeMenu={() => toggleMenu(false)} />
        {!isWiki && (
          <div
            className="BaseLayoutLogOutIcon FlexRow"
            onClick={() => makeLogout({ logout })}
          >
            <LogOutIcon />
            <span>{baseLayoutText.exit}</span>
          </div>
        )}
      </div>
      <div
        className="BaseLayoutContentWrapper"
        style={{
          display: menuOpen ? `none` : `inherit`,
          background: bgColor || routeBackground.root
        }}
      >
        <ErrorBoundary
          render={() => (menuReady ? children : <div />)}
          renderError={() => <ErrorBoundaryComponent />}
        />
      </div>
    </div>
  )
}

const getComponentByRoute = (route: string, logout: () => void) => {
  switch (route) {
    case MAIN_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <MainPage />
        </BaseLayoutUser>
      )
    case CASHBOX_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <CashboxComponent />
        </BaseLayoutUser>
      )
    case CONSOLIDATED_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <ConsolidatedPage />
        </BaseLayoutUser>
      )
    case GAMES_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <GamePage />
        </BaseLayoutUser>
      )
    case CONTACTS_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <ContactComponent />
        </BaseLayoutUser>
      )
    case NOTIFICATION_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <NotificationsComponent />
        </BaseLayoutUser>
      )
    case MESSAGES_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <MessagesRoute />
        </BaseLayoutUser>
      )
    case CHANGE_PHONE_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <ChangePhoneComponent />
        </BaseLayoutUser>
      )
    case REPORT_NONCASH_TRANSACTION:
      return (
        <BaseLayoutUser logout={logout}>
          <Transaction />
        </BaseLayoutUser>
      )
    case REPORT_NONCASH_BALANCE_OPERATION:
      return (
        <BaseLayoutUser logout={logout}>
          <BalanceOperation />
        </BaseLayoutUser>
      )
    case REPORT_NONCASH_CLUB_BALANCE:
      return (
        <BaseLayoutUser logout={logout}>
          <ClubBalance />
        </BaseLayoutUser>
      )
    case GUEST_INFO:
      return (
        <BaseLayoutUser logout={logout}>
          <GuestInfoComponent />
        </BaseLayoutUser>
      )
    case SUPPORT_PROPOSAL:
      return (
        <BaseLayoutUser logout={logout}>
          <Proposal />
        </BaseLayoutUser>
      )
    case HANDBOOK_NEW_HALL:
      return (
        <BaseLayoutUser logout={logout}>
          <NewHall />
        </BaseLayoutUser>
      )
    case MAILING_PAGE:
      return (
        <BaseLayoutUser logout={logout}>
          <Mailing />
        </BaseLayoutUser>
      )
    case SUPPORT_PENALTY_ADMIN:
      return (
        <BaseLayoutUser logout={logout}>
          <AdminPenalty />
        </BaseLayoutUser>
      )
    case SUPPORT_PENALTY_USER:
      return (
        <BaseLayoutUser logout={logout}>
          <UserPenalty />
        </BaseLayoutUser>
      )
    case VISIT:
      return (
        <BaseLayoutUser logout={logout}>
          <VisitComponent />
        </BaseLayoutUser>
      )
    case GUIDEBOOK:
      return (
        <BaseLayoutUser logout={logout}>
          <Guidebook />
        </BaseLayoutUser>
      )
    case SMS:
      return (
        <BaseLayoutUser logout={logout}>
          <SmsComponent />
        </BaseLayoutUser>
      )
    case GUESTS_HISTORY:
      return (
        <BaseLayoutUser logout={logout}>
          <GuestsHistory />
        </BaseLayoutUser>
      )
    case REPORT_CONSOLIDATED_CUPIS:
      return (
        <BaseLayoutUser logout={logout}>
          <Cupis />
        </BaseLayoutUser>
      )
    case GUESTS_BALANCE:
      return (
        <BaseLayoutUser logout={logout}>
          <GuestsBalance />
        </BaseLayoutUser>
      )
    case OFFICIAL_SMS:
      return (
        <BaseLayoutUser logout={logout}>
          <OfficialSms />
        </BaseLayoutUser>
      )
    case WIKI:
      return (
        <BaseLayoutUser logout={logout}>
          <Wiki />
        </BaseLayoutUser>
      )
    case SPORT_BOOM:
      return (
        <BaseLayoutUser logout={logout}>
          <SportBoomReport />
        </BaseLayoutUser>
      )
    case INSURANCES:
      return (
        <BaseLayoutUser logout={logout}>
          <InsurancesComponent />
        </BaseLayoutUser>
      )
    case SUPERWIN:
      return (
        <BaseLayoutUser logout={logout}>
          <SuperwinReport />
        </BaseLayoutUser>
      )
    case TOP_THREE:
      return (
        <BaseLayoutUser logout={logout}>
          <TopThreeComponent />
        </BaseLayoutUser>
      )
    case RACE:
      return (
        <BaseLayoutUser logout={logout}>
          <Race />
        </BaseLayoutUser>
      )
    case NOMINALS:
      return (
        <BaseLayoutUser logout={logout}>
          <Nominals />
        </BaseLayoutUser>
      )
    case PAYROLLS:
      return (
        <BaseLayoutUser logout={logout}>
          <PayrollsComponent />
        </BaseLayoutUser>
      )
    case ORDER_PAYOUTS:
      return (
        <BaseLayoutUser logout={logout}>
          <QiwiOrder />
        </BaseLayoutUser>
      )
    case PAYMENT:
      return (
        <BaseLayoutUser logout={logout}>
          <Payment />
        </BaseLayoutUser>
      )
    case REPORT_HALL:
      return (
        <BaseLayoutUser logout={logout}>
          <ReportHall />
        </BaseLayoutUser>
      )
    case HALL_CARD:
      return (
        <BaseLayoutUser logout={logout}>
          <HallCard />
        </BaseLayoutUser>
      )
    case HISTORY_BETS:
      return (
        <BaseLayoutUser logout={logout}>
          <HistoryBets />
        </BaseLayoutUser>
      )
    case REGISTRATION_CHECK:
      return (
        <BaseLayoutUser logout={logout}>
          <RegistrationCheck />
        </BaseLayoutUser>
      )
    case SOPD_REPORT:
      return (
        <BaseLayoutUser logout={logout}>
          <SopdReport />
        </BaseLayoutUser>
      )
    case PROMOTION_BET_CHECK:
      return (
        <BaseLayoutUser logout={logout}>
          <PromotionBetBonus />
        </BaseLayoutUser>
      )
    case PROMOTION_BIRTHDAY:
      return (
        <BaseLayoutUser logout={logout}>
          <BirthdayBonus />
        </BaseLayoutUser>
      )
    case PROMOTION_GOLD_RUSH:
      return (
        <BaseLayoutUser logout={logout}>
          <PromotionGoldRush />
        </BaseLayoutUser>
      )
    case HALL_SPORT_BROADCAST:
      return (
        <BaseLayoutUser logout={logout}>
          <SportsBroadcast />
        </BaseLayoutUser>
      )
    case HALL_GEOSERVICES:
      return (
        <BaseLayoutUser logout={logout}>
          <Geoservices />
        </BaseLayoutUser>
      )
    case GUEST_FILTERS:
      return (
        <BaseLayoutUser logout={logout}>
          <GuestFilters />
        </BaseLayoutUser>
      )
    case HALL_ACCESS:
      return (
        <BaseLayoutUser logout={logout}>
          <AccessReport />
        </BaseLayoutUser>
      )
    case YANDEX_TAXI:
      return (
        <BaseLayoutUser logout={logout}>
          <YandexTaxi />
        </BaseLayoutUser>
      )
  }
}

const BaseLayout = (): JSX.Element => {
  const history = useHistory()
  // use redux store
  const developer: IDevelopState = useSelector(getDeveloperList)
  const isAuth: boolean = useSelector(getIsAuth)
  const current_hall = useSelector(getCurrentHall)

  const { request: getMessage } = useRequest({
    url: `message/emergency`,
    method: "POST",
    requestBody: { hall_id: current_hall[0] }
  })
  const dispatch = useDispatch()
  const login: (data: IUSERState) => void = data => dispatch(loginUser(data))
  const addDeveloperLink: (data: IDevelopState) => void = data =>
    dispatch(addLink(data))
  const logout: () => void = () => dispatch(logoutUser())
  const storeDictionary: (data: IDictionaryState) => void = data =>
    dispatch(storeDictionaryData(data))
  const storeMenu: (data: IMenuState) => void = data =>
    dispatch(storeMenuData(data))
  const setStoreEmergency: (value: MessageStoreState) => void = (
    value: MessageStoreState
  ) => dispatch(storeEmergency(value))
  const emergencyStore = useSelector(getEmergencyCount)
  //
  const userFromStorage: string = localStorage.getItem(`user`) || ``
  const user = userFromStorage ? JSON.parse(userFromStorage) : undefined

  const [pending, setPending] = React.useState<boolean>(false)

  const hallDeps = current_hall ? current_hall.join(``) : false

  useEffect(() => {
    if (!!hallDeps && isAuth) {
      getDictionary({
        hall: current_hall[0],
        developer,
        addDeveloperLink,
        history,
        logout,
        storeDictionary,
        setPending
      }).then(() => {
        getMenu({
          hall: `${current_hall}`,
          setPending,
          developer,
          addDeveloperLink,
          history,
          logout,
          storeMenu
        }).catch(() => setPending(false))
      })
    }
  }, [isAuth])

  const [message, setMessage] = useState<MessageProps>({
    data: [],
    isOpen: false
  })

  useEffect(() => {
    if (isAuth && current_hall[0]) {
      const makeRequest = () =>
        getMessage().then(
          ({
            data: { result, show_popup }
          }: MessagesResponse<EmergencyDataType[]>) => {
            setStoreEmergency({
              message_count: 0,
              emergency: result,
              emergency_open: show_popup && result && !!result.length
            })
          }
        )
      const haveWait = localStorage.getItem(`emergency_wait`)
      if (!!haveWait) {
        const currentTime = new Date().getTime()
        const maxWait = 60000 * 15
        const diff = currentTime - Number(haveWait)
        if (diff > maxWait) {
          makeRequest()
          localStorage.removeItem(`emergency_wait`)
        } else {
          setTimeout(() => makeRequest(), 20000)
          return
        }
      } else {
        makeRequest()
      }
    }
    // // TODO find something better
    // if (!isAuth && window.location.pathname !== `/`) {
    //   window.location.href = `/`
    // }
  }, [isAuth, current_hall[0]])

  const routes = useSelector(getMenuRoutes)
  const base = baseRoute(routes)

  // TODO: use for canary release
  const { isCanary, isAccessCanary, isYandex } = roleConstants()

  return (
    <div>
      {emergencyStore.emergency_open && isAuth && !!current_hall[0] && (
        <ModalComponent width={544}>
          <MessagesComponent
            message={{
              ...message,
              data: emergencyStore.emergency,
              isOpen: emergencyStore.emergency_open
            }}
            setMessage={setMessage}
          />
        </ModalComponent>
      )}
      {!!user && isAuth && current_hall && !pending && !!current_hall.length && (
        <Router>
          <Switch>
            {routes.map(item => {
              if (!!item) {
                return (
                  <Route
                    key={`route_${item}`}
                    exact={item !== `/messages` && item !== WIKI}
                    path={item}
                  >
                    {getComponentByRoute(item, logout)}
                  </Route>
                )
              }
            })}
            {localStorage.getItem(`role`) === `COMPANY-SUPPORT` && (
              <Route exact path={GUESTS_HISTORY}>
                {getComponentByRoute(GUESTS_HISTORY, logout)}
              </Route>
            )}
            {isCanary && (
              <Route exact path={GUEST_FILTERS}>
                <BaseLayoutUser logout={logout}>
                  <GuestFilters />
                </BaseLayoutUser>
              </Route>
            )}
            <Route exact path={ERROR_PAGE}>
              <BaseLayoutUser logout={logout}>
                <ErrorPage baseUrl={base} />
              </BaseLayoutUser>
            </Route>
            <Route exact path={HALL_SPORT_BROADCAST}>
              <BaseLayoutUser logout={logout}>
                <SportsBroadcast />
              </BaseLayoutUser>
            </Route>
            {isAccessCanary && (
              <Route exact path={HALL_ACCESS}>
                <BaseLayoutUser logout={logout}>
                  <AccessReport />
                </BaseLayoutUser>
              </Route>
            )}
            {isYandex && (
              <Route exact path={YANDEX_TAXI}>
                <BaseLayoutUser logout={logout}>
                  <YandexTaxi />
                </BaseLayoutUser>
              </Route>
            )}
            {!!base && <Redirect to={base} />}
          </Switch>
        </Router>
      )}
      {(!user || !isAuth) && <Authentication isAuth={!!user} login={login} />}
      {isAuth && !current_hall.length && <HallPickerPage />}
      <div />
    </div>
  )
}

export default BaseLayout
