import React from "react"
import { DownloadModalProps } from "./types"
import { ModalFormComponent } from "Components/Modal"
import FileComponent from "Components/File"
import { downloadModalTitleText } from "../constants"

const DownloadModal: React.FC<DownloadModalProps> = ({ file, close }) => {
  return (
    <ModalFormComponent
      close={close}
      width={424}
      className={"YandexFileAttachedModal"}
      title={<div>{downloadModalTitleText}</div>}
    >
      <FileComponent
        key={`file_${file?.name}`}
        name={file?.name ?? ""}
        size={file?.size}
        customDownloadLink={file?.url}
      />
    </ModalFormComponent>
  )
}

export default DownloadModal
