import { Durations, FilterDurations } from "Components/FilterInfoPanel/types"
import { YandexModalFields, YandexModalFormValues } from "./Modal/types"
import moment from "moment/moment"
import { PrepareYandexDataToSaveType } from "./types"
import { YandexFilesTableState } from "./YandexFilesTable/types"
import { Moment } from "moment"

export const yandexTaxiConstants = {
  periodOptions: [
    { value: FilterDurations.daily, label: `День` },
    { value: FilterDurations.monthly, label: `Месяц` }
  ],
  daily: `daily`
}

export const initialYandexFileTableState: YandexFilesTableState = {
  items: [],
  pending: false,
  total_count: 0
}

export const initialValues: YandexModalFormValues = {
  [YandexModalFields.type]: FilterDurations.daily,
  [YandexModalFields.period]: undefined,
  [YandexModalFields.files]: []
}

export const yandexFileRule = (empty: boolean) =>
  empty
    ? Promise.resolve()
    : Promise.reject(new Error(`Обязательно прикрепить файл`))

const formatDate = (date: Moment) =>
  `${date.format("YYYY-MM-DD")}T00:00:00.000+03:00`

export const prepareYandexDataToSave = ({
  values,
  files
}: PrepareYandexDataToSaveType) => {
  const dates: { from?: string; to?: string } = {}

  if (values.type === FilterDurations.monthly) {
    dates.from = formatDate(values.period.startOf("month"))
    dates.to = formatDate(values.period.endOf("month"))
  } else {
    dates.from = dates.to = formatDate(moment(values.period))
  }

  return {
    file_id: files[0].id,
    typeOfPeriod:
      values.type === FilterDurations.monthly ? Durations.month : Durations.day,
    dates
  }
}

export const fileModalConstants = {
  saveUrl: `v1/hall/yandex-taxi/save`,
  description: `Прикрепите 1 файл, не более 10Мб`,
  format: `Формат: xls`,
  allowed: `.xls, .xlsx`,
  ok: `Хорошо`,
  datePickerPlaceholder: `__.__.____`,
  perodLabel: `Тип периода`,
  upload: `Загрузить`,
  uploadTitle: `Загрузка файла`
}
