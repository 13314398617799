import { TooltipPlacement } from "antd/lib/tooltip"
import { Moment } from "moment"
import moment from "moment/moment"
import { Durations } from "Components/FilterInfoPanel/types"
import {
  yandexFileFilterFields,
  yandexFileTableFields
} from "./YandexFilesTable/constants"
import { YandexFilesFilterProps } from "./YandexFilesTable/types"

export const YandexTabsTitleText = {
  add: `Добавить файл`,
  titleText: `Яндекс такси`,
  titleTrigger: `click`,
  tooltipDirection: `bottom` as TooltipPlacement,
  tooltipText: `Отчет для просмотра истории заказов Яндекс такси для Бизнеса. Содержит детальную информацию о поездках, а также информацию о балансе клуба в приложении.Загрузка информации о поездках прошедшего дня происходит утром следующего дня. Загрузка информации о поездках, совершенных в выходные и праздничные дни, происходит утром в первый рабочий день.`,
  tabs: {
    upload: `Загрузка файлов`,
    history: `Отчет по Яндекс такси`
  }
}

export const disabledDate = (current: Moment) => {
  //Нельзя выбрать даты > последней в текущем месяце
  const tooLate =
    current &&
    current >
      moment()
        .subtract(1, "days")
        .endOf(Durations.day)
  //Нельзя выбрать даты < последней в текущем месяце
  const tooEarly = current && current < moment().startOf(Durations.month)

  return tooEarly || tooLate
}

export const prepareSortFiled = (
  field: string | undefined,
  yandexFilesFilterState: YandexFilesFilterProps
) => {
  if (!field) {
    return yandexFilesFilterState.sort.field || yandexFileFilterFields.date
  }

  switch (field) {
    case yandexFileTableFields.dates:
      return yandexFileFilterFields.date
    case yandexFileTableFields.file:
      return yandexFileFilterFields.status
  }
}
