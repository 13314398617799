import React, { useEffect, useMemo, useState } from "react"
import { useRequest, useRequestBody } from "api/hooks"
import { YandexDetailReport, YandexHistoryFilter } from "../types"
import TableNew from "Components/TableNew"
import {
  getYandexDetailsColumns,
  prepareYandexRequestBody,
  yandexHistoryConstants
} from "../constants"
import moment from "moment"
import { PickerFormats } from "Components/FilterInfoPanel/types"
import { getDateDescriptionLayout } from "constants/index"

const YandexDetails = ({
  filterState
}: {
  filterState: YandexHistoryFilter
}) => {
  const { pagination, setPagination } = useRequestBody(20)

  const [detailsData, setDetailsData] = useState<YandexDetailReport>({
    pending: false,
    total_count: 0,
    items: []
  })

  const initRequestBody = prepareYandexRequestBody(filterState, pagination)

  const { request } = useRequest({
    url: yandexHistoryConstants.urlDetails,
    method: `POST`,
    requestBody: {
      ...initRequestBody,
      filter: {
        date: moment(filterState.data.date).format(
          yandexHistoryConstants.dateFormat
        ),
        gmt: initRequestBody.filter.gmt,
        isShift: initRequestBody.filter.isShift,
        shift: initRequestBody.filter.shift,
        halls: initRequestBody.filter.halls
      }
    }
  })

  useEffect(() => {
    if (!filterState.data.dates) return

    setDetailsData({ ...detailsData, pending: true })

    request()
      .then(({ data }: any) => {
        setDetailsData({
          ...detailsData,
          pending: false,
          total_count: data.total_count,
          items: data.items
        })
      })
      .catch(() => {
        setDetailsData({ ...detailsData, pending: false })
      })
  }, [pagination])

  const columns = getYandexDetailsColumns()

  const titleDetailsDescription = useMemo(() => {
    if (!!filterState.data.date) {
      return getDateDescriptionLayout({
        dates: [moment(filterState.data.date), moment(filterState.data.date)],
        shift: filterState.data.shift,
        className: filterState.data.isShift
          ? ""
          : "YandexDescriptionDetailsItemToLeft",
        hideTime: false,
        hidePeriodTitle: true,
        calendarIcon: false,
        isShift: filterState.data.isShift,
        show: filterState.isActive
      })
    }
    return <></>
  }, [filterState.data.date, filterState.data.isShift, filterState.isActive])

  return (
    <div className="YandexDetailWrapper">
      <div className="YandexDetailTitle FlexColumn">
        <span className="HeadlineSecondText">
          {moment(filterState.data.date).format(PickerFormats.dayMonthYear)}
        </span>
        <div className="YandexDescriptionDetailsItem TextTwo">
          {filterState.data.isShift &&
            yandexHistoryConstants.detailDescriptionPrefix}
          {titleDetailsDescription}
        </div>
      </div>
      <div className="YandexDetailTable">
        <TableNew
          width={1200}
          data={detailsData.items}
          tableClassName="OrangeHeaderTable"
          loading={detailsData.pending}
          columns={columns}
          pagination={{
            value: pagination,
            total: detailsData.total_count,
            onChange: setPagination
          }}
        />
      </div>
    </div>
  )
}

export default YandexDetails
