import { rulesText, checkEmail } from "./constants"

import { Moment } from "moment"

export const rules = {
  required: [
    {
      validator: <T, D>(_: T, value: D) =>
        value && ((value as unknown) as string).trim()
          ? Promise.resolve()
          : Promise.reject(new Error(rulesText.required))
    }
  ],
  required_long: [
    {
      validator: <T, D>(_: T, value: D) =>
        value && ((value as unknown) as string).trim()
          ? Promise.resolve()
          : Promise.reject(new Error(rulesText.required_long))
    }
  ],
  required_short: [
    {
      validator: <T, D>(_: T, value: D) => {
        return value && ((value as unknown) as string).trim()
          ? Promise.resolve()
          : Promise.reject(new Error(rulesText.required_short))
      }
    }
  ],
  required_array: [
    {
      validator: <T, D>(_: T, value: D[]) => {
        return value && !!value.length
          ? Promise.resolve()
          : Promise.reject(new Error(rulesText.required_short))
      }
    }
  ],
  required_date: {
    short: [
      {
        validator: <T>(_: T, value?: Moment) => {
          return !!value
            ? Promise.resolve()
            : Promise.reject(new Error(rulesText.required_short))
        }
      }
    ],
    long: [
      {
        validator: <T>(_: T, value?: [Moment, Moment]) => {
          return !!value && !!value[0] && !!value[1]
            ? Promise.resolve()
            : Promise.reject(new Error(rulesText.required_short))
        }
      }
    ],
    longObj: [
      {
        validator: <T>(_: T, value?: { from: Moment; to: Moment }) => {
          return !!value && !!value.from && !!value.to
            ? Promise.resolve()
            : Promise.reject(new Error(rulesText.required_short))
        }
      }
    ]
  },
  email: [
    {
      validator: <T>(_: T, value?: string) => {
        const isEmail = checkEmail(value)
        if (!isEmail) {
          return Promise.reject(new Error(rulesText.email))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  phone: [
    {
      validator: <T>(_: T, value?: string) => {
        if (!value || value?.length !== 11) {
          return Promise.reject(new Error(rulesText.phone))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  number: [
    {
      validator: <T>(_: T, value?: string) => {
        if (!!value && !Number(value)) {
          return Promise.reject(new Error(rulesText.number))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  integer: [
    {
      validator: <T>(_: T, value?: string) => {
        const integerRegex = /^\d+$/
        if (!!value && !integerRegex.test(value)) {
          return Promise.reject(new Error(rulesText.default))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  comma_number: [
    {
      validator: <T>(_: T, value?: string) => {
        const commaNumberRegex = /^\d+\,\d{2}$/
        if (
          !!value &&
          !commaNumberRegex.test(value) &&
          (!Number(value) || `${value}`.includes(`.`))
        ) {
          return Promise.reject(new Error(rulesText.comma_number))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  dot_number: [
    {
      validator: <T>(_: T, value?: string) => {
        const commaNumberRegex = /^\d+\.\d{2}$/
        if (
          !!value &&
          !commaNumberRegex.test(value) &&
          (!Number(value) || `${value}`.includes(`.`))
        ) {
          return Promise.reject(new Error(rulesText.dot_number))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  required_phone: [
    {
      validator: <T>(_: T, value?: string) => {
        if (!value) {
          return Promise.reject(new Error(rulesText.required))
        } else if (value?.length !== 11) {
          return Promise.reject(new Error(rulesText.phone))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  required_phone_mask: [
    {
      validator: <T>(_: T, value?: string) => {
        if (!value) {
          return Promise.reject(new Error(rulesText.required))
        } else if (!!value && value?.slice(-1) === `_`) {
          return Promise.reject(new Error(rulesText.phone))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  required_email: [
    {
      validator: <T>(_: T, value: string | undefined) => {
        const emptyVal = !value
        const isEmail = checkEmail(value)
        if (emptyVal) {
          return Promise.reject(new Error(rulesText.required))
        } else if (!isEmail) {
          return Promise.reject(new Error(rulesText.email))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  required_halls: [
    {
      validator: <T>(_: T, value: string | undefined) => {
        const emptyVal = !value
        if (emptyVal || !value?.length) {
          return Promise.reject(new Error(rulesText.required))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  max_length_100: [
    {
      validator: <T, D>(_: T, value: D) => {
        if (value && ((value as unknown) as string).length > 100) {
          return Promise.reject(new Error(rulesText.max_length_100))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  inn: [
    {
      validator: <T, D>(_: T, value: D) => {
        if (
          ((value as unknown) as string)?.length !== 10 &&
          ((value as unknown) as string)?.length !== 12
        ) {
          return Promise.reject(new Error(rulesText.inn))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  name: [
    {
      validator: <T, D>(_: T, value: D) => {
        const nameRegex = /^[a-zA-Z\u0400-\u04FF\-\s]+$/
        if (!!value && !nameRegex.test(`${value}`)) {
          return Promise.reject(new Error(rulesText.name))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  login: [
    {
      validator: <T, D>(_: T, value: D) => {
        const nameRegex = /^(?!.*\+)[A-Za-z0-9\p{P}\p{S}]*$/u
        if (!!value && !nameRegex.test(`${value}`)) {
          return Promise.reject(new Error(rulesText.login))
        } else {
          return Promise.resolve()
        }
      }
    }
  ],
  required_login: [
    {
      validator: <T>(_: T, value: string | undefined) => {
        const nameRegex = /^[A-Za-z0-9\p{P}\p{S}]*$/u
        if (value) {
          return Promise.reject(new Error(rulesText.required))
        } else if (!nameRegex.test(`${value}`)) {
          return Promise.reject(new Error(rulesText.login))
        } else {
          return Promise.resolve()
        }
      }
    }
  ]
}
