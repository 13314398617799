import Title from "Components/Title"
import { isMobile } from "constants/index"
import { YandexTabsTitleText } from "../helper"
import ExcelButton from "Components/ExcelButton"
import { yandexHistoryConstants } from "../YandexHistory/constants"
import QiwiModalComponent from "Components/QiwiModal"
import DashboardComponent from "Components/DashboardNew"
import {
  DashboardOptions,
  DashboardPrefixOptions
} from "Components/DashboardNew/constants"
import moment from "moment/moment"
import YandexHistory from "../YandexHistory"
import React from "react"
import { PartnerViewProps } from "../types"

const PartnerView = ({
  filterState,
  setFilterState,
  exelReq,
  titleDescription,
  showDashboardModal,
  toggleShowDashboardModal,
  dashBoardState,
  setDashBoardState,
  showDashboard
}: PartnerViewProps) => {
  return (
    <div className="MainPageContentWrapper YandexMainPageContentWrapper">
      <div
        className={`MailingContentTitleWrapper YandexContentTitleWrapper YandexContentTitleWrapperExcel`}
      >
        <Title
          trigger={isMobile ? YandexTabsTitleText.titleTrigger : ``}
          direction={YandexTabsTitleText.tooltipDirection}
          titleText={YandexTabsTitleText.titleText}
          tooltipText={YandexTabsTitleText.tooltipText}
          rightAdditionalCmp={
            filterState.isActive && (
              <div className="YandexTitleExel">
                {
                  <ExcelButton
                    url={yandexHistoryConstants.urlXls}
                    requestBody={exelReq}
                  />
                }
              </div>
            )
          }
          rightAdditionalClassName="MailingContentTitleRight"
          wrapperClass="YandexContentTitleWrapper"
          bottomAdditionalCmp={
            <>
              {filterState.isActive && titleDescription}
              <div className="YandexDescription">
                {showDashboardModal && (
                  <div>
                    <QiwiModalComponent
                      close={() => toggleShowDashboardModal(false)}
                      data={dashBoardState.balance?.halls || []}
                      name="sum"
                      title={yandexHistoryConstants.dashboardColumn}
                      color={yandexHistoryConstants.dashboardModalColor}
                    />
                  </div>
                )}
                {filterState.isActive && showDashboard && (
                  <DashboardComponent
                    loading={dashBoardState.pending}
                    config={[
                      {
                        onClick: () => {
                          toggleShowDashboardModal(true)
                        },
                        prefix: DashboardPrefixOptions.RUB,
                        type: DashboardOptions.yandex_taxi,
                        value: `${dashBoardState.balance?.sum}` ?? 0,
                        isCurrency: true,
                        description: `На ${moment(
                          dashBoardState.balance?.date
                        ).format(yandexHistoryConstants.dashboardDateFormat)}`,
                        tooltipText: yandexHistoryConstants.dashboardTooltip
                      }
                    ]}
                  />
                )}
              </div>
            </>
          }
        />
      </div>
      <YandexHistory
        filterState={filterState}
        setFilterState={setFilterState}
        dashBoardState={dashBoardState}
        setDashBoardState={setDashBoardState}
      />
    </div>
  )
}

export default PartnerView
