import React, { useCallback } from "react"

import { Skeleton } from "antd"

import { getCurrencyLayout, valueToCurrency } from "constants/index"

import { ReactComponent as InfoIcon } from "Components/icons/help.svg"
import TooltipComponent from "Components/Tooltip"

import { DashboardComponentProps, DashboardComponentValue } from "./types"
import { isNull } from "lodash"
import { configByOption, prefixOptions, defaultMoreText } from "./constants"

const formatValueWithSpaces = (value: DashboardComponentValue) => {
  return isNull(value)
    ? ``
    : valueToCurrency({
        value: Number(value),
        withoutFixed: true,
        withoutZero: true
      })
}

const DashboardItemLoading = () => (
  <div className="NewDashboard_Item_Loading">
    <Skeleton.Button active />
    <div className="Dashboard_Item_Loading_Wrapper FlexColumn Gap8">
      <div className="FlexRow Gap8">
        <div className="Dashboard_Item_Loading_Icon" />
        <div className="FlexColumn Gap8">
          <div className="Dashboard_Item_Loading_Label" />
          <div className="Dashboard_Item_Loading_Label" />
        </div>
      </div>
      <div className="FlexColumn Gap4">
        <div className="Dashboard_Item_Loading_Description" />
        <div className="Dashboard_Item_Loading_Value" />
      </div>
    </div>
  </div>
)

const DashboardComponent = ({
  currencyDeps,
  config,
  loading,
  extraBlock,
  withMore
}: DashboardComponentProps) => {
  const getCurrency = useCallback(getCurrencyLayout, [currencyDeps])
  return (
    <div className="NewDashboard FlexRow Gap16">
      {config.map((item, idx) => {
        const currencyValue = item.isCurrency
          ? getCurrency(item.value, true)
          : formatValueWithSpaces(item.value)
        const { icon, color, title } = configByOption[item.type]
        const valueExist = !!item.value || item.value == 0
        const fullLoading = loading || item.extraLoading

        const haveMore = !!item.moreToggle
        return fullLoading ? (
          <DashboardItemLoading />
        ) : (
          <div
            key={idx}
            className={`FlexColumn NewDashboard_Item ${
              withMore ? `NewDashboard_ItemWithMore` : ``
            } ${!!item.onClick ? `NewDashboard_Item_Clickable` : ``}`}
            style={{ backgroundColor: color }}
            onClick={item.onClick}
          >
            <div className="FlexRow MarginBottom8 Gap12 NewDashboard_Item_Title">
              <div className="NewDashboard_Item_IconWrapper">{icon}</div>
              <span
                className="NewDashboard_Item_Title TextOneBold"
                style={{ width: item.customWidth }}
              >
                {item.title || title}
              </span>
            </div>
            <span className="CaptionTextBold Gray600Color MarginBottom4">
              {item.description}
            </span>
            <div className="FlexRow">
              {valueExist && !fullLoading && (
                <div className={`FlexRow Gap4 NewDashboard_Item_Value`}>
                  <span className="HeadlineSecondText">
                    {item.isCurrency && !item.isString
                      ? currencyValue
                      : item.value}
                  </span>

                  <span className={`HeadlineSecondText`}>
                    {item.prefix ? prefixOptions[item.prefix] : ``}
                  </span>
                </div>
              )}
              {haveMore && !fullLoading && (
                <div
                  className="NewDashboard_Item_MoreButton ButtonTextSecond"
                  onClick={item.moreToggle}
                >
                  {item.moreText || defaultMoreText.more}
                </div>
              )}
            </div>
            <div
              onClick={event => {
                event.stopPropagation()
              }}
              className="NewDashboard_Item_InfoWrapper"
            >
              <TooltipComponent
                direction={item.tooltipDirection ?? "bottom"}
                title={item.tooltipText}
              >
                <InfoIcon />
              </TooltipComponent>
            </div>
          </div>
        )
      })}
      {extraBlock || <div className="DisplayNoneElement" />}
    </div>
  )
}

export default DashboardComponent
