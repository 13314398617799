import { UseRequestBodyPagination, UseRequestBodySort } from "api/hooks"

export type YandexFile = {
  name: string
  url: string
  size: number
  uploadDttm: string
}

export type YandexFilesTableData = {
  date: [string, string] | [string, null]
  file: YandexFile | null
}

export type YandexFilesTableState = {
  total_count: number
  pending: boolean
  items: YandexFilesTableData[]
}

export type YandexFilesFilterProps = {
  pagination: UseRequestBodyPagination
  sort: UseRequestBodySort
}

export enum YandexTableStatusRus {
  uploaded = `Загружен`,
  absent = `Отсутствует`
}

export enum YandexTableStatus {
  uploaded = `uploaded`,
  absent = `absent`
}

export type getColumnsProps = {
  filterState: YandexFilesFilterProps
  setFilterState: (val: YandexFilesFilterProps) => void
  setModalState: (val: boolean) => void
  fileNameHandler: (file: YandexFile) => void
}
