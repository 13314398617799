import React, { JSX, useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { v4 as uuidv4 } from "uuid"

import { Form, Select } from "antd"

import { useRequest, useRequestParams } from "api/hooks"

import { isMobile, roleConstants } from "constants/index"

import { AccessTemplates } from "store/access/types"
import { CardsListData } from "../Cards/types"
import { EmployeesWithOutAccessData, FormProps } from "./types"
import { DefaultOptionType } from "rc-select/lib/Select"
import { getAccessList } from "store/access/reducer"

import betRequest from "api/request"
import { rules } from "Components/Form/rules"
import { CheckBoxComponent } from "Components/CheckBox"
import { ButtonPrimary } from "Components/Button"
import InputComponent, { InputMaskComponent } from "Components/Input"
import { HallsMultiSelect, SelectComponent } from "Components/Select"
import { TooltipCustom, TooltipCustomSize } from "Components/Tooltip"
import CancelModal from "./cancel"

import {
  DashboardTagRender,
  formatSnils,
  generatePassword,
  getDashboardOptions,
  homeAccessTag,
  initialForm,
  initialRequiredList,
  labelText,
  statusOptions,
  unionFio,
  stuffUrl
} from "./constants"

import CrudAccessMenu from "./menu"
import MenuTags from "./tags"
import AccessMenuMobile from "./menu_mobile"
import Spin from "Components/Spin"
import { ReactComponent as DeleteIcon } from "Components/icons/crud/delete_outline_16.svg"

const AccessModal = ({
  closeModal,
  data
}: {
  closeModal: (withRefresh?: boolean, isEdit?: boolean) => void
  data?: CardsListData
}) => {
  const { dashboards, templates } = useSelector(getAccessList)

  const dashboardOptions = useMemo(() => getDashboardOptions(dashboards), [
    dashboards
  ])

  const [form] = Form.useForm()
  const isEdit = !!data

  const { isPartner } = roleConstants()
  const viewMode = isPartner

  const initialData = useMemo(() => initialForm(data), [])

  const [formState, setFormState] = useState<FormProps>({
    pending: false,
    data: initialData
  })

  const isRestrictedByTemplates =
    AccessTemplates.by_request === formState.data.template_name ||
    AccessTemplates.ldap === formState.data.template_name

  useEffect(() => {
    isRestrictedByTemplates &&
      form.setFields([
        {
          name: labelText.snils.field,
          value: ""
        }
      ])
  }, [isRestrictedByTemplates])

  const [phone, setPhone] = useState<string | undefined>(initialData?.phone)
  const [snils, setSnils] = useState<string | undefined>(initialData?.snils)
  const [newPositionName, setNewPositionName] = useState("")
  const [fetching, setFetching] = useState(false)
  const [positionsAdded, setPositionsAdded] = useState<DefaultOptionType[]>([])
  const [positions, setPositions] = useState<{
    label: JSX.Element
    options: DefaultOptionType[]
  }>(labelText.position.initPosition)

  const initialRequired = useMemo(() => {
    if (isEdit) {
      const currentTemplate = templates.find(
        item => item.key === data.template_name
      )
      return currentTemplate?.menu_items_required || initialRequiredList
    } else {
      return initialRequiredList
    }
  }, [])

  const [currentRequiredList, setCurrentRequiredList] = useState<string[]>(
    initialRequired
  )

  const templatesOptions = templates
    .map(item => ({
      value: item.key,
      label: item.name
    }))
    .filter(option => {
      if (
        option.value === AccessTemplates.by_request &&
        (!isEdit || initialData.template_name !== AccessTemplates.by_request)
      )
        return false
      else if (
        formState.data.template_name !== AccessTemplates.ldap &&
        option.value === AccessTemplates.ldap
      ) {
        return false
      }
      return option
    })

  const isCustom = formState.data.template_name === AccessTemplates.by_request

  const statuses = useMemo(
    () => statusOptions({ current: initialData.status }),
    [formState.data.status]
  )

  const removeMenuItem = useCallback(
    (removeId: string) => {
      if (!!formState.data.menu_items) {
        const newMenu = formState.data.menu_items.filter(
          item => item !== removeId
        )
        setFormState({
          ...formState,
          data: { ...formState.data, menu_items: newMenu }
        })
      }
    },
    [formState]
  )

  const { request: saveData } = useRequest({
    url: `v1/access/save`,
    requestBody: {
      id: data?.id,
      ...formState.data,
      phone,
      snils
    }
  })

  const disableTemplates = useMemo(
    () => isEdit && initialData.template_name === AccessTemplates.by_request,
    []
  )

  useEffect(() => {
    setFormState({
      ...formState,
      data: { ...formState.data, full_name: unionFio(formState) }
    })
  }, [formState.data.name, formState.data.surname, formState.data.patronymic])

  const onFinish = () => {
    setFormState({ ...formState, pending: true })

    saveData()
      .then(() => {
        setFormState({ ...formState, pending: false })
        closeModal(true, isEdit)
      })
      .catch(({ response }) => {
        setFormState({ ...formState, pending: false })
        if (response?.data?.errors) {
          if (response?.data?.errors?.login) {
            form.setFields([
              {
                name: labelText.login.field,
                errors: [response?.data?.errors?.login]
              }
            ])
          }
          if (response?.data?.errors?.email) {
            form.setFields([
              {
                name: labelText.email.field,
                errors: [response?.data?.errors?.email]
              }
            ])
          }
          if (response?.data?.errors?.menu_items) {
            form.setFields([
              {
                name: labelText.menu_items.field,
                errors: [response?.data?.errors?.menu_items]
              }
            ])
          }
        }
      })
  }

  const { request: getPositions } = useRequest({
    url: labelText.positionsListUrl
  })

  const { request: addPositionReq } = useRequest({
    url: labelText.positionManageUrl,
    method: "POST",
    requestBody: { name: newPositionName }
  })

  const { request: getStuff } = useRequest({
    url: stuffUrl
  })

  const [optionsForUsers, setOptionsForUsers] = useState<DefaultOptionType[]>(
    []
  )

  useEffect(() => {
    if (!isPartner) {
      getStuff().then(data => {
        const options = data.data.map((item: EmployeesWithOutAccessData) => {
          return {
            label: item.name,
            value: item.name,
            position: item.position,
            snils: item.snils,
            halls: item.halls
          }
        })
        setOptionsForUsers(options)
      })
    }

    getPositions().then(data => {
      setFetching(false)
      const positionsOpts: DefaultOptionType[] = []
      const positionsAddedOpts: DefaultOptionType[] = []

      data.data.forEach((item: { id: number; name: string }) => {
        if (item.id) {
          positionsAddedOpts.push(createNewPositionElement(item.name, item.id))
        } else {
          positionsOpts.push({
            label: item.name,
            value: item.name
          })
        }
      })

      setPositions({
        ...positions,
        options: [...positions.options, ...positionsOpts]
      })

      setPositionsAdded([...positionsAdded, ...positionsAddedOpts])
    })
  }, [])

  const filterOption = useCallback(
    (inputValue, option) =>
      (option.value as string).toLowerCase().includes(inputValue.toLowerCase()),
    [optionsForUsers, formState.data.full_name]
  )

  const { history, logout } = useRequestParams()

  const deletePos = (name: string, id: number) => {
    betRequest({
      method: `DELETE`,
      url: labelText.positionManageUrl,
      requestBody: { id },
      history,
      logout
    }).then(() => {
      setFormState(prev => {
        if (prev.data.position === name) {
          form.resetFields([labelText.position.field])
          return {
            ...prev,
            data: { ...prev.data, position: undefined }
          }
        } else {
          return prev
        }
      })

      setPositionsAdded(prev => {
        return prev.filter(item => {
          return item.id !== id
        })
      })
    })
  }

  const createNewPositionElement = (name: string, id: number) => {
    return {
      label: (
        <div className={"AccessModalNewPosition"}>
          <span>{name}</span>
          <DeleteIcon
            onClick={event => {
              if (fetching) return false

              event.stopPropagation()
              event.preventDefault()
              deletePos(name, id)
            }}
          />
        </div>
      ),
      value: name,
      id: id
    }
  }

  const addNewPosition = () => {
    setFetching(true)
    addPositionReq().then(data => {
      setFetching(false)

      setPositionsAdded(prev => {
        if (
          !(
            positionsAdded
              .map(element => element.value)
              .includes(newPositionName) ||
            positions.options
              .map(element => element.label)
              .includes(newPositionName)
          )
        ) {
          return [
            ...prev,
            createNewPositionElement(newPositionName, data.data.id)
          ]
        } else {
          return prev
        }
      })
    })
  }

  const hasDuplicates = () =>
    positionsAdded.map(element => element.value).includes(newPositionName) ||
    positions.options.map(element => element.label).includes(newPositionName)

  const onChangeFioHandler = (value: string) => {
    const currentOption = optionsForUsers?.find(item => item.value === value)

    setSnils(currentOption?.snils)

    form.setFieldValue(labelText.snils.field, currentOption?.snils)

    form.setFieldValue(labelText.position.field, currentOption?.position)

    form.setFieldValue(labelText.halls.field, currentOption?.halls)

    setFormState(prev => ({
      ...formState,
      data: {
        ...formState.data,
        full_name: value,
        position: currentOption?.position,
        snils: currentOption?.snils,
        halls: currentOption?.halls
      }
    }))
  }

  return (
    <div className={`AccessModalWrapper MainPageContentWrapper FlexColumn`}>
      <CancelModal
        close={() => closeModal(false)}
        isEdit={isPartner ? false : isEdit}
      />
      <div className="AccessModalContent FullSizeWidth">
        <Form onFinish={onFinish} form={form} initialValues={initialData}>
          <div
            className={`MarginBottom32 ${
              isMobile ? `FlexColumn Gap16` : `FlexRow Gap32`
            }`}
          >
            <div className="FlexColumn AccessModalContentColumn">
              {/*ФИО*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="FlexRow FullSizeWidth">
                  <div className="SubtitleFirstText">
                    {labelText.full_name.label}
                  </div>
                  {!isPartner && (
                    <div className="MarginLeftAuto">
                      <CheckBoxComponent
                        className="AccessModalUnemployedCheckbox"
                        disabled={isRestrictedByTemplates}
                        label={labelText.unemployed.label}
                        value={!formState.data.employed}
                        checked={!formState.data.employed}
                        onChange={({ target: { checked } }) => {
                          setFormState({
                            ...formState,
                            data: { ...formState.data, employed: !checked }
                          })
                          form.resetFields()
                        }}
                      />
                    </div>
                  )}
                </div>

                {formState.data.employed && !isPartner && (
                  <Form.Item
                    rules={[...rules.required, ...rules.name]}
                    name={labelText.full_name.field}
                  >
                    <SelectComponent
                      showSearch={true}
                      allowClear={true}
                      disabled={formState.pending || viewMode}
                      maxLength={100}
                      placeholder={labelText.full_name.placeholder}
                      notFoundContent={fetching ? <Spin /> : null}
                      options={optionsForUsers}
                      filterOption={filterOption}
                      value={formState.data.full_name}
                      onChange={onChangeFioHandler}
                    />
                  </Form.Item>
                )}

                {!formState.data.employed && !isPartner && (
                  <div className="AccessModalInputGroup">
                    <Form.Item
                      rules={[...rules.required, ...rules.name]}
                      name={labelText.surname.field}
                    >
                      <InputComponent
                        disabled={formState.pending || viewMode}
                        maxLength={100}
                        placeholder={labelText.surname.placeholder}
                        value={formState.data.surname}
                        onChange={({ target: { value } }) => {
                          setFormState({
                            ...formState,
                            data: { ...formState.data, surname: value }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[...rules.required, ...rules.name]}
                      name={labelText.name.field}
                    >
                      <InputComponent
                        disabled={formState.pending || viewMode}
                        maxLength={100}
                        placeholder={labelText.name.placeholder}
                        value={formState.data.name}
                        onChange={({ target: { value } }) => {
                          setFormState({
                            ...formState,
                            data: { ...formState.data, name: value }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={rules.name}
                      name={labelText.patronymic.field}
                    >
                      <InputComponent
                        disabled={formState.pending || viewMode}
                        maxLength={100}
                        placeholder={labelText.patronymic.placeholder}
                        value={formState.data.patronymic}
                        onChange={({ target: { value } }) => {
                          setFormState({
                            ...formState,
                            data: { ...formState.data, patronymic: value }
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                )}

                {isPartner && (
                  <div className="AccessModalInputGroup">
                    <Form.Item
                      rules={[...rules.required, ...rules.name]}
                      name={labelText.full_name.field}
                    >
                      <InputComponent
                        disabled={formState.pending || viewMode}
                        maxLength={100}
                        placeholder={labelText.full_name.placeholder}
                        onChange={({ target: { value } }) => {
                          setFormState({
                            ...formState,
                            data: { ...formState.data, full_name: value }
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>

              {/*СНИЛС*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.snils.label}</div>
                <Form.Item
                  rules={
                    isRestrictedByTemplates
                      ? [labelText.snils.custom_rule]
                      : [...rules.required, labelText.snils.custom_rule]
                  }
                  name={labelText.snils.field}
                >
                  <InputComponent
                    value={snils}
                    allowClear={false}
                    disabled={
                      formState.pending ||
                      viewMode ||
                      formState.data.employed ||
                      isRestrictedByTemplates
                    }
                    placeholder={labelText.snils.placeholder}
                    onChange={x => {
                      const value = formatSnils(`${x.target.value}`)
                      setSnils(value)
                      form.setFieldValue(labelText.snils.field, value)
                    }}
                  />
                </Form.Item>
              </div>

              {/*Логин*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.login.label}</div>
                <Form.Item
                  rules={[
                    ...rules.required,
                    ...rules.login,
                    labelText.login.custom_rule
                  ]}
                  name={labelText.login.field}
                >
                  <InputComponent
                    disabled={
                      formState.pending || viewMode || isRestrictedByTemplates
                    }
                    maxLength={50}
                    placeholder={labelText.login.placeholder}
                    onChange={({ target: { value } }) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, login: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>

              {/*Шаблон*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">
                  {labelText.template_name.label}
                </div>
                <Form.Item
                  rules={[...rules.required]}
                  name={labelText.template_name.field}
                >
                  <SelectComponent
                    allowClear={false}
                    disabled={
                      formState.pending ||
                      disableTemplates ||
                      viewMode ||
                      isRestrictedByTemplates
                    }
                    options={templatesOptions}
                    placeholder={labelText.template_name.placeholder}
                    onChange={value => {
                      const currentTemplate = templates?.find(
                        item => item.key === value
                      )
                      setFormState({
                        ...formState,
                        data: {
                          ...formState.data,
                          template_name: value,
                          dashboards: currentTemplate?.dashboards,
                          menu_items: currentTemplate?.menu_items || []
                        }
                      })
                      setCurrentRequiredList(
                        currentTemplate?.menu_items_required || []
                      )
                    }}
                  />
                </Form.Item>
              </div>

              {/*Должности*/}
              <div>
                <div className={`FlexColumn Gap4 FullSizeWidth`}>
                  <div className="SubtitleFirstText">
                    {labelText.position.label}
                  </div>
                  <Form.Item
                    rules={!isCustom ? [...rules.required] : []}
                    name={labelText.position.field}
                  >
                    {!formState.data.employed ? (
                      <SelectComponent
                        allowClear={false}
                        disabled={
                          formState.pending ||
                          viewMode ||
                          formState.data.employed ||
                          isRestrictedByTemplates
                        }
                        maxLength={100}
                        dropdownRender={menu => {
                          return (
                            <div>
                              {menu}
                              <div className={"AccessModalFormWrapper"}>
                                <div className={"AccessModalInputContainer"}>
                                  <InputComponent
                                    value={newPositionName}
                                    onChange={({ target: { value } }) => {
                                      setNewPositionName(value)
                                    }}
                                  />
                                </div>
                                <div className={"AccessModalButtonContainer"}>
                                  <ButtonPrimary
                                    disabled={fetching}
                                    htmlType="button"
                                    onClick={() => {
                                      // Если еще нет должности с таким названием
                                      !hasDuplicates() && addNewPosition()
                                    }}
                                  >
                                    {labelText.position.createBtn}
                                  </ButtonPrimary>
                                </div>
                              </div>
                            </div>
                          )
                        }}
                        options={
                          positionsAdded.length === 0
                            ? [...positions.options]
                            : [...positionsAdded, positions]
                        }
                        value={formState.data.position}
                        notFoundContent={fetching ? <Spin /> : null}
                        placeholder={labelText.position.placeholder}
                        onChange={value => {
                          setFormState({
                            ...formState,
                            data: {
                              ...formState.data,
                              position: value
                            }
                          })
                        }}
                        listItemHeight={10}
                        listHeight={250}
                      />
                    ) : (
                      <InputComponent
                        disabled={
                          formState.pending ||
                          viewMode ||
                          formState.data.employed
                        }
                        value={formState.data.position}
                        placeholder={labelText.position.placeholder}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              {/*Статус доступа*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="FlexRow Gap4">
                  <div className="SubtitleFirstText">
                    {labelText.status.label}
                  </div>
                  <div className="MarginTop4">
                    <TooltipCustom
                      size={TooltipCustomSize.small}
                      title={labelText.status.tooltip}
                    />
                  </div>
                </div>
                <Form.Item
                  rules={[...rules.required]}
                  name={labelText.status.field}
                >
                  <SelectComponent
                    allowClear={false}
                    disabled={formState.pending || viewMode}
                    options={statuses}
                    placeholder={labelText.status.placeholder}
                    onChange={value => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, status: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>

              {/*Доступ к клубам*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.halls.label}</div>
                <Form.Item
                  rules={[...rules.required_array]}
                  name={labelText.halls.field}
                >
                  <HallsMultiSelect
                    allowClear={false}
                    disabled={
                      formState.pending || viewMode || isRestrictedByTemplates
                    }
                    placeholder={labelText.halls.placeholder}
                    onChange={(value: any) => {
                      setFormState(prev => ({
                        ...prev,
                        data: { ...prev.data, halls: value }
                      }))
                    }}
                  />
                </Form.Item>
              </div>

              {/*Пункты меню*/}
              {isMobile && (
                <div className="FlexColumn Gap4 FullSizeWidth MarginBottom24">
                  <div className="SubtitleFirstText">
                    {labelText.menu_items.label}
                  </div>
                  <Form.Item name={labelText.menu_items.field}>
                    <AccessMenuMobile
                      items={formState.data.menu_items}
                      disabled={viewMode}
                      required_items={currentRequiredList}
                      disabledAll={!formState.data.template_name}
                      changeItems={(value: string[]) => {
                        setFormState({
                          ...formState,
                          data: { ...formState.data, menu_items: value }
                        })
                      }}
                    />
                  </Form.Item>
                </div>
              )}

              {/*Телефон*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.phone.label}</div>
                <Form.Item
                  rules={rules.required_phone_mask}
                  name={labelText.phone.field}
                >
                  <InputMaskComponent
                    mask={"+7 000 000-00-00"}
                    disabled={formState.pending || viewMode}
                    value={phone}
                    allowClear={false}
                    onChange={x => {
                      const value = `7${x.unmaskedValue}`
                      form.setFieldValue(labelText.phone.field, value)
                      setPhone(value)
                    }}
                  />
                </Form.Item>
              </div>

              {/*Email*/}
              <div className="FlexColumn Gap4 FullSizeWidth">
                <div className="SubtitleFirstText">{labelText.email.label}</div>
                <Form.Item
                  rules={rules.required_email}
                  name={labelText.email.field}
                >
                  <InputComponent
                    readOnly={viewMode}
                    disabled={formState.pending || viewMode}
                    maxLength={100}
                    placeholder={labelText.email.placeholder}
                    onChange={({ target: { value } }) => {
                      setFormState({
                        ...formState,
                        data: { ...formState.data, email: value }
                      })
                    }}
                  />
                </Form.Item>
              </div>

              {/*Пароль*/}
              {!viewMode && (
                <div className="FlexColumn Gap4 FullSizeWidth">
                  <div className="FlexRow FullSizeWidth">
                    <div className="SubtitleFirstText">
                      {labelText.password.label}
                    </div>
                    {!isRestrictedByTemplates && (
                      <div
                        className="MarginLeftAuto LinkText HoverItem"
                        onClick={() => {
                          const newPassword = generatePassword()
                          form.setFieldValue(
                            labelText.password.field,
                            newPassword
                          )
                          setFormState({
                            ...formState,
                            data: { ...formState.data, password: newPassword }
                          })
                        }}
                      >
                        {labelText.password.link}
                      </div>
                    )}
                  </div>
                  <Form.Item
                    rules={isEdit ? [] : rules.required}
                    name={labelText.password.field}
                  >
                    <InputComponent
                      disabled={formState.pending || isRestrictedByTemplates}
                      placeholder={labelText.password.placeholder}
                      onChange={({ target: { value } }) => {
                        setFormState({
                          ...formState,
                          data: { ...formState.data, password: value }
                        })
                      }}
                    />
                  </Form.Item>
                </div>
              )}

              {/*Отправить пароль*/}
              {!viewMode && (
                <div className="FlexColumn Gap4 MarginBottom8 FullSizeWidth AccessCrudPasswordSend">
                  <Form.Item name={labelText.send_password.field}>
                    <CheckBoxComponent
                      disabled={
                        formState.pending ||
                        !formState.data.password ||
                        isRestrictedByTemplates
                      }
                      label={labelText.send_password.label}
                      checked={formState.data.send_to_email}
                      onChange={({ target: { checked } }) => {
                        setFormState({
                          ...formState,
                          data: { ...formState.data, send_to_email: checked }
                        })
                      }}
                    />
                  </Form.Item>
                </div>
              )}

              {viewMode ? (
                <ButtonPrimary
                  loading={formState.pending}
                  htmlType="button"
                  onClick={() => closeModal(false)}
                >
                  {labelText.submit.ok}
                </ButtonPrimary>
              ) : (
                <Form.Item>
                  <ButtonPrimary loading={formState.pending} htmlType="submit">
                    {isEdit ? labelText.submit.save : labelText.submit.create}
                  </ButtonPrimary>
                </Form.Item>
              )}
            </div>

            {/*Доступные пункты меню*/}
            {!isMobile && (
              <div className="FlexColumn Gap16 AccessModalContentColumn">
                <CrudAccessMenu
                  templateName={formState.data.template_name}
                  checked={formState.data.menu_items}
                  disabledAll={!formState.data.template_name || viewMode}
                  requiredList={currentRequiredList}
                  onChange={(val: string[]) => {
                    setFormState({
                      ...formState,
                      data: { ...formState.data, menu_items: val }
                    })
                  }}
                />
              </div>
            )}

            {/*Добавленные пункты меню*/}
            {!isMobile && (
              <div className="FlexColumn Gap16 AccessModalContentColumn">
                <Form.Item name={labelText.menu_items.field}>
                  <MenuTags
                    menu_items={formState.data.menu_items}
                    required_items={currentRequiredList}
                    removeMenuItem={removeMenuItem}
                  />
                </Form.Item>

                {/*Дашборды*/}
                {formState.data.menu_items.includes(homeAccessTag) && (
                  <div className="FlexColumn Gap4 FullSizeWidth MarginBottom24 MarginTop24">
                    <div className="FlexRow Gap4">
                      <div className="SubtitleFirstText">
                        {labelText.dashboards.label}
                      </div>
                      <div className="MarginTop4">
                        <TooltipCustom
                          size={TooltipCustomSize.small}
                          title={labelText.dashboards.tooltip}
                        />
                      </div>
                    </div>
                    <SelectComponent
                      allowClear={false}
                      mode="multiple"
                      tagRender={props => DashboardTagRender(props, viewMode)}
                      disabled={formState.pending || viewMode}
                      value={formState.data.dashboards}
                      className="AccessDashboardSelectWrapper"
                      placeholder={labelText.dashboards.placeholder}
                      onChange={value => {
                        setFormState({
                          ...formState,
                          data: { ...formState.data, dashboards: value }
                        })
                      }}
                    >
                      {dashboardOptions.map(item =>
                        !!item ? (
                          <Select.Option
                            className="AccessDashboardSelectItemWrapper"
                            key={`dashboard_${item.value}`}
                            value={item.value}
                          >
                            <div className="FlexRow Gap6">
                              <CheckBoxComponent
                                onChange={({ target: { checked } }) => {
                                  const currentDashboards =
                                    formState.data.dashboards || []
                                  form.setFieldValue(
                                    labelText.dashboards.field,
                                    currentDashboards
                                  )
                                  if (checked) {
                                    setFormState({
                                      ...formState,
                                      data: {
                                        ...formState.data,
                                        dashboards: [
                                          ...currentDashboards,
                                          item.value
                                        ]
                                      }
                                    })
                                  } else {
                                    const newDashboards = currentDashboards.filter(
                                      dash => dash !== item.value
                                    )
                                    setFormState({
                                      ...formState,
                                      data: {
                                        ...formState.data,
                                        dashboards: newDashboards
                                      }
                                    })
                                  }
                                }}
                                checked={formState.data.dashboards?.includes(
                                  item.value
                                )}
                              />
                              <div>{item.icon}</div>
                              <div className="TextOne">{item.label}</div>
                            </div>
                          </Select.Option>
                        ) : (
                          <div key={uuidv4()} />
                        )
                      )}
                    </SelectComponent>
                  </div>
                )}
              </div>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AccessModal
