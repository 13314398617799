import { CardsListData } from "./Cards/types"
import { UseRequestBodyPagination } from "api/hooks"

export enum AccessStatuses {
  active = `active`,
  paused = `paused`,
  resuming = `resuming`
}

export enum AccessTemplates {
  manager = `manager`,
  admin = `admin`,
  cashier = `cashier`,
  custom = `custom`,
  by_request = `by_request`
}

export type AccessFiltersData = {
  halls: number[]
  search?: string
  status: AccessStatuses | 0
}

export type AccessFilters = {
  data: AccessFiltersData
  isOpen: boolean
  mobileFilter: boolean
  isActive: boolean
}

export type ViewProps = {
  isCrud: boolean
  data?: CardsListData
}

export type AccessBaseProps = {
  openModal: (data?: CardsListData) => void
  refreshTrigger: number
  refresh: () => void
  pagination: UseRequestBodyPagination
  setPagination: (val: UseRequestBodyPagination) => void
}
